import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import Button from '../../components/common/button/button';
import Project from '../project';

const Projects = ({
  campaignProjects,
  selectedProjects,
  setSelectedProjects,
  row,
  column,
  setBuy,
  unit,
  minOffset,
}) => {
  const [selectedRow, setSelectedRow] = useState();
  const [rowNr, setRowNr] = useState(0);
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (column && column > 0) {
      var rows = [];
      for (var i = 0; i < campaignProjects.length; i += row * column) {
        rows.push(campaignProjects.slice(i, i + row * column));
      }
      setRows(rows);
    }
  }, [campaignProjects, row, column]);

  useEffect(() => {
    setSelectedRow(rows[rowNr]);
  }, [rows, rowNr]);

  useEffect(() => {
    setTotal(selectedProjects.reduce((acc, scope) => acc + (parseFloat(scope?.amount) || 0), 0));
  }, [selectedProjects]);

  return (
    <ProjectsWrapper className="flex-column flex-1" rows={row} columns={column}>
      <div className="px-6 pt-5 top-icon">
        <img height={32} src="https://api.dev2.meta-carbon.cloud/icons/d10b5484-ed38-11ee-9378-42010a9a0035/active" />
      </div>
      <div className="grid project-grid px-6 pt-6">
        {selectedRow?.map(cp => (
          <div key={cp.id} className="flex">
            <Project
              campaignProject={cp}
              selectedProjects={selectedProjects}
              setSelectedProjects={setSelectedProjects}
              unit={unit}
            />
          </div>
        ))}
      </div>
      <div className="flex items-center justify-between px-6 pt-6 pb-4 mt-6 no-select">
        <div
          className="flex items-center cursor proj-btn"
          style={{ opacity: rowNr === 0 ? 0.5 : 1 }}
          onClick={() => setRowNr(rowNr > 0 ? rowNr - 1 : 0)}>
          <img height={12} src="https://api.dev2.meta-carbon.cloud/icons/d10b5484-ed38-11ee-9378-42010a9a0040/active" />
          <label className="lato-400-text font-12 ml-2">Prev project</label>
        </div>
        <div className="flex items-center cursor">
          {rows.map((r, i) => (
            <div
              key={i}
              className="row-nr"
              style={{
                background: rowNr === i ? '#24CBB1' : '#DADCE0',
                margin: '3px',
                width: rowNr === i ? '16px' : '6px',
              }}
              onClick={() => setRowNr(i)}
            />
          ))}
        </div>
        <div
          className="flex items-center cursor proj-btn"
          style={{ opacity: rowNr === rows.length - 1 ? 0.5 : 1 }}
          onClick={() => setRowNr(rowNr === rows.length - 1 ? rows.length - 1 : rowNr + 1)}>
          <label className="lato-400-text font-12 mr-2">Next project</label>
          <img height={12} src="https://api.dev2.meta-carbon.cloud/icons/d10b5484-ed38-11ee-9378-42010a9a0039/active" />
        </div>
      </div>
      {minOffset && (
        <div className="flex items-center justify-between border-top pxy-6 no-select">
          <label className="quicksand-700-text font-20">Recommended amount:</label>
          <label
            className="quicksand-700-text font-20"
            style={{ color: total < minOffset / (unit === 'KILO' ? 1 : 1000) ? 'red' : '' }}>
            {Math.ceil(minOffset / (unit === 'KILO' ? 1 : 1000)).toLocaleString('en-US')}{' '}
            {unit === 'KILO' ? 'kilo' : 'tonnes'}
          </label>
        </div>
      )}
      <div className="flex items-center justify-between border-top pxy-6 no-select">
        <label className="quicksand-700-text font-20">Total amount:</label>
        <label className="quicksand-700-text font-20">
          {total.toLocaleString('en-US')} {unit === 'KILO' ? 'kilo' : 'tonnes'}
        </label>
      </div>
      <div className="flex items-center justify-between border-top pxy-6 no-select">
        <label className="quicksand-700-text font-20">Total:</label>
        <label className="quicksand-700-text font-20">
          $
          {selectedProjects
            .filter(sp => sp?.amount)
            .reduce(
              (acc, scope) =>
                acc +
                (parseFloat(scope?.amount) || 0) *
                  scope?.product?.prices?.find(p => p.recurring.interval === 'SINGLE').amount *
                  (unit === 'KILO' ? 1 : 1000),
              0,
            )
            .toLocaleString('en-US')}
        </label>
      </div>
      <div className="flex justify-center px-6 mt-2 pb-6">
        <Button
          // disabled={total === 0 || Math.ceil(minOffset && total < minOffset / (unit === 'KILO' ? 1 : 1000))}
          label="Buy"
          size="large"
          borderRadius="16px"
          bgColor="#24CBB1"
          color="#ffffff"
          className="buy-btn"
          onClick={() => setBuy(true)}
        />
      </div>
    </ProjectsWrapper>
  );
};

const ProjectsWrapper = styled.div`
  .project-grid {
    grid-template-columns: ${({ columns }) => `repeat(${columns}, 1fr)`};
    height: ${({ rows }) => `${rows * 310}px`};
    column-gap: 16px;
    row-gap: 16px;
  }

  .proj-btn {
    padding: 5px 8px;
    border-radius: 6px;
    border: 1px solid #dadce0;
    background: #ffffff;
  }

  .row-nr {
    width: 6px;
    height: 6px;
    min-width: 6px;
    min-height: 6px;
    border-radius: 100px;
  }

  .buy-btn {
    max-width: 428px;
  }

  .disabled {
    background: rgba(201, 226, 222, 1);
    border-color: rgba(201, 226, 222, 1);
  }
`;

export default Projects;
