import { configureStore } from '@reduxjs/toolkit';
import reloadSlice from './features/reloadSlice';
import toastSlice from './features/toastSlice';
import campaignSlice from './features/campaignSlice';

export default configureStore({
  reducer: {
    reload: reloadSlice,
    toast: toastSlice,
    campaign: campaignSlice,
  },
  devTools: process.env.REACT_APP_STAGE === 'development',
});
