import React from 'react';
import { RouterProvider, createBrowserRouter, Route, Routes } from 'react-router-dom';
import Toast from '../components/Toast/toast';
import Main from '../pages/main/main';

export const Router = ({ widget, minOffset, measurement }) => {
  return (
    <>
      <RouterProvider
        router={createBrowserRouter([
          {
            path: '/*',
            element: <Main widget_id={widget} minOffset={minOffset} measurement={measurement} />,
          },
          {
            path: '/:id',
            element: <Main widget_id={widget} minOffset={minOffset} measurement={measurement} />,
          },
        ])}
      />
      <Toast />
    </>
  );
};
