import { css } from 'styled-components';

const text = css`
  font-size: 14px;
  color: ${({ theme }) => theme.black};
`;

export const FontStyles = css`
  .lato-100-text {
    ${text}
    font-family: Lato;
    font-weight: 100;
  }

  .lato-300-text {
    ${text}
    font-family: Lato;
    font-weight: 300;
  }

  .lato-400-text {
    ${text}
    font-family: Lato;
    font-weight: 400;
  }

  .lato-500-text {
    ${text}
    font-family: Lato;
    font-weight: 500;
  }

  .lato-700-text {
    ${text}
    font-family: Lato;
    font-weight: 700;
  }

  .lato-900-text {
    ${text}
    font-family: Lato;
    font-weight: 900;
  }

  .quicksand-300-text {
    ${text}
    font-family: Quicksand;
    font-weight: 300;
  }

  .quicksand-400-text {
    ${text}
    font-family: Quicksand;
    font-weight: 400;
  }

  .quicksand-500-text {
    ${text}
    font-family: Quicksand;
    font-weight: 500;
  }

  .quicksand-600-text {
    ${text}
    font-family: Quicksand;
    font-weight: 600;
  }

  .quicksand-700-text {
    ${text}
    font-family: Quicksand;
    font-weight: 700;
  }

  .primary-text {
    color: ${({ theme }) => theme.primary_500};
  }

  .grey-text-2 {
    color: ${({ theme }) => theme.greyText2};
  }

  .natural-300-text {
    color: ${({ theme }) => theme.natural_300};
  }

  .natural-400-text {
    color: ${({ theme }) => theme.natural_400};
  }

  .natural-500-text {
    color: ${({ theme }) => theme.natural_500};
  }

  .natural-700-text {
    color: ${({ theme }) => theme.natural_700};
  }

  .natural-900-text {
    color: ${({ theme }) => theme.natural_900};
  }

  .orange-500-text {
    color: ${({ theme }) => theme.orange_500};
  }

  .success-500-text {
    color: ${({ theme }) => theme.success_500};
  }

  .puple-500-text {
    color: ${({ theme }) => theme.purple_500};
  }

  .purple-50-text {
    color: ${({ theme }) => theme.purple_50};
  }

  .primary-500-text {
    color: ${({ theme }) => theme.primary_500};
  }

  .error-text {
    color: ${({ theme }) => theme.error_500};
  }

  .black-text {
    color: ${({ theme }) => theme.black};
  }

  .quicksand-black {
    font-family: 'Quicksand', sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
  }

  .ramabhadra-regular {
    font-family: 'Ramabhadra', sans-serif;
    font-weight: 400;
    font-style: normal;
  }
`;
