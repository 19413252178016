import { createSlice } from '@reduxjs/toolkit';
import api from '../services/index';

export const campaignSlice = createSlice({
  name: 'campaign',
  initialState: {
    campaignProjects: [],
    campaign: {},
    loadingCampaignProjects: true,
  },
  reducers: {
    setCampaignProjects: (state, { payload }) => {
      state.campaignProjects = payload;
    },
    setCampaign: (state, { payload }) => {
      state.campaign = payload;
    },
    setLoadingCampaignProjects: (state, { payload }) => {
      state.loadingCampaignProjects = payload;
    },
  },
});

export const getCampaignProjects = payload => async dispatch => {
  const { data } = await api.get(`/v3/api/backoffice/campaigns/${payload.campaignId}/projects?has_product=true`, {
    headers: { 'x-api-key': payload.api_key },
  });
  dispatch(setCampaignProjects(data));
  dispatch(setLoadingCampaignProjects(false));
};

export const getCampaign = payload => async dispatch => {
  const { data } = await api.get(`/v3/api/backoffice/campaigns/${payload.campaignId}`, {
    headers: { 'x-api-key': payload.api_key },
  });
  dispatch(setCampaign(data));
};

export const createContact = payload => async dispatch => {
  try {
    const { data } = await api.post(`/v1/contacts`, payload.request, {
      headers: { 'x-api-key': payload.api_key },
    });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createContactPm = payload => async dispatch => {
  try {
    const { data } = await api.post(`/v1/contacts/${payload.contactId}/payment_methods`, payload.request, {
      headers: { 'x-api-key': payload.api_key },
    });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createContactSusbscription = payload => async dispatch => {
  try {
    const { data } = await api.post(`/v1/contacts/${payload.contactId}/subscriptions`, payload.request, {
      headers: { 'x-api-key': payload.api_key },
    });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createContactPaymentIntent = payload => async dispatch => {
  try {
    const { data } = await api.post(`/v1/contacts/${payload.contactId}/payment_intents`, payload.request, {
      headers: { 'x-api-key': payload.api_key },
    });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getFormDetails = payload => async dispatch => {
  try {
    const { form_id, store = false } = payload;
    const { data } = await api.get(`/v1/self-service/forms/${form_id}`);
    if (store) dispatch(setFormDetails(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getWidget = payload => async dispatch => {
  try {
    const { widget_id, store = false } = payload;
    const { data } = await api.get(`/v3/api/self-service/widgets/${widget_id}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getFormPanels =
  ({ api_key, form_id, store = false }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/v1/self-service/forms/${form_id}/panels`, getHeaders(api_key));
      if (store) dispatch(setFormPanels(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getFormPanelWidgets =
  ({ api_key, panel_id, store }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/v1/self-service/panels/${panel_id}/widgets`, getHeaders(api_key));
      if (store) dispatch(setFormPanelWidgets(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getFormConfigs = payload => async dispatch => {
  try {
    const { form_id, api_key, store } = payload;
    const { data } = await api.get(`/v1/forms/${form_id}/configs`, getHeaders(api_key));
    if (store) dispatch(setFormConfigs(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createTransactions = payload => async dispatch => {
  try {
    const { data } = await api.post(`/v3/api/backoffice/transactions`, payload.request, {
      headers: { 'x-api-key': payload.api_key },
    });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

const getHeaders = api_key => {
  return { headers: { 'x-api-key': api_key } };
};

export const { setCampaignProjects, setCampaign, setLoadingCampaignProjects } = campaignSlice.actions;
export default campaignSlice.reducer;
