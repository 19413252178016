import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import store from './store/store';

const element = document.getElementById('purchase_widget');
const root = ReactDOM.createRoot(element);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App
        widget={element?.dataset?.widget}
        minOffset={element?.dataset?.min}
        measurement={element?.dataset?.measurement}
      />
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
const configuration = {
  onUpdate: registration => {
    if (registration && registration.waiting) {
      store.dispatch(update(registration));
    }
  },
};

serviceWorkerRegistration.register(configuration);
