import React from 'react';

import styled from 'styled-components';

import CurrencyInput from 'react-currency-input-field';

const Project = ({ campaignProject, selectedProjects, setSelectedProjects, unit }) => {
  const selected = selectedProjects.find(p => p.id === campaignProject.id) || false;
  const getPriceName = price => {
    if (price.recurring.interval === 'SINGLE') return 'One-time';
    if (price.recurring.interval === 'MONTH') return 'Monthly';
    if (price.recurring.interval === 'YEAR') return 'Yearly';
  };
  return (
    <ProjectWrapper className="flex-column flex-1 cursor no-select">
      <div className="project-item flex-column relative">
        <div
          className="flex-column"
          onClick={() =>
            setSelectedProjects(
              selectedProjects.includes(campaignProject)
                ? selectedProjects.filter(p => p.id !== campaignProject.id)
                : [...selectedProjects, campaignProject],
            )
          }>
          <img
            className="w-full"
            srcSet={campaignProject?.project?.image?.media?.map(m => `${m.url} ${m.width}w`).join(', ')}
            height={170}
          />
          {/* <div
            className="select-project absolute flex items-center justify-center"
            style={{
              background: selected ? '#24CBB1' : '#FFFFFF',
              borderColor: selected ? '#24CBB1' : '#b5b9c1',
            }}>
            {selected && (
              <img
                height="8px"
                src="https://api.dev2.meta-carbon.cloud/icons/d10b5484-ed38-11ee-9378-42010a9a0038/active"
              />
            )}
          </div> */}
          <div className="flex-column justify-between project-info pxy-3">
            <div className="flex justify-between">
              <label className="lato-700-text font-14 white-text one-line">{campaignProject?.project?.name}</label>
              {/* <img
                className="ml-2 mt-05 cursor"
                height={16}
                src="https://api.dev2.meta-carbon.cloud/icons/d10b5484-ed38-11ee-9378-42010a9a0036/active"
              /> */}
            </div>
            <div className="flex mt-2">
              <label className="lato-400-text font-14 white-text">
                ${(campaignProject?.product?.prices?.find(p => p.recurring.interval === 'SINGLE').amount || 0) * 1000}{' '}
                per tonne
              </label>
            </div>
            <div className="flex mt-4">
              <img
                className="mr-1 mt-05 cursor"
                height={12}
                src="https://api.dev2.meta-carbon.cloud/icons/d10b5484-ed38-11ee-9378-42010a9a0037/active"
              />
              <label className="lato-400-text font-12 white-text">{campaignProject?.project?.location?.country}</label>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between mt-6 px-2">
        <p className="lato-500-text font-14">Amount</p>
        <div className="flex items-center carbon-input ml-2">
          <CurrencyInput
            className="mr-1 lato-400-text font-14"
            decimalsLimit={3}
            onValueChange={value =>
              selectedProjects.find(p => p.id === campaignProject.id)
                ? setSelectedProjects(
                    selectedProjects.map(p => (p.id === campaignProject.id ? { ...p, amount: value } : p)),
                  )
                : setSelectedProjects([...selectedProjects, { ...campaignProject, amount: value }])
            }
            value={selectedProjects.find(p => p.id === campaignProject.id)?.amount ?? 0}
          />
          <p className="lato-400-text font-16">{unit === 'KILO' ? 'kilo' : 'tonnes'}</p>
        </div>
      </div>
    </ProjectWrapper>
  );
};

const ProjectWrapper = styled.div`
  width: 220px;
  .project-item {
    border-radius: 8px;
    overflow: hidden;
    img {
      object-fit: cover;
    }

    .project-info {
      background: #020819;
    }
  }

  .carbon-input {
    width: 120px;
    border-radius: 12px;
    border: 1px solid rgba(181, 185, 193, 0.5);
    padding: 3px 8px;
    background: #ffffff;

    input {
      border: none;
      background: transparent;
      width: 55px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .select-project {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    border-radius: 8px;
    border: 1px solid #b5b9c1;
    right: 12px;
    top: 12px;
  }
`;

export default Project;
