import React, { useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import {
  createContact,
  createContactPaymentIntent,
  createContactPm,
  createContactSusbscription,
  createTransactions,
} from '../../store/features/campaignSlice';

import Button from '../../components/common/button/button';

import { nanoid } from 'nanoid';
import { useDispatch } from 'react-redux';

const Purchase = ({
  api_key,
  campaign,
  campaignProjects,
  setCampaignProjects,
  back,
  unit,
  minOffset,
  onDone,
  measurement,
}) => {
  const dispatch = useDispatch();
  const prices = [
    { id: 'SINGLE', name: 'ONE-TIME' },
    { id: 'MONTH', name: 'MONTHLY' },
    { id: 'YEAR', name: 'YEARLY' },
  ];
  const [selectedPrice, setSelectedPrice] = useState(prices[0]);

  const StripePayment = () => {
    const elements = useElements();
    const navigate = useNavigate();
    const location = useLocation();
    const stripe = useStripe();
    const [name, setName] = useState();
    const [email, setEmail] = useState();

    const [paying, setPaying] = useState(false);
    const onSubmit = async () => {
      setPaying(true);
      const { error: submitError } = await elements.submit();
      if (submitError) {
        return;
      }
      const names = name.split(' ');
      const contact = await dispatch(
        createContact({
          api_key,
          request: {
            title: '',
            forename: names[0],
            surname: names[1],
            emails: [{ email_type: 'PERSONAL', is_primary: true, value: email }],
            integration: { id: campaign.payment_integration?.id },
          },
        }),
      );
      const { paymentMethod } = await stripe.createPaymentMethod({
        elements,
        params: { billing_details: { name: name, email: email } },
      });

      await dispatch(
        createContactPm({
          contactId: contact.id,
          api_key,
          request: {
            external_reference: paymentMethod.id,
            integration: { id: campaign.payment_integration?.id },
            is_default: true,
          },
        }),
      );

      const contactSub =
        selectedPrice.id === 'SINGLE'
          ? await dispatch(
              createContactPaymentIntent({
                contactId: contact.id,
                api_key,
                request: {
                  integration: { id: campaign.payment_integration?.id },
                  payment_method: { id: paymentMethod.id },
                  amount: campaignProjects
                    .map(
                      cp =>
                        parseFloat(cp.amount) *
                        (unit === 'KILO' ? 1 : 1000) *
                        cp?.product?.prices.filter(p => p.recurring.interval === selectedPrice.id).map(p => p.amount),
                    )
                    .reduce((acc, cur) => acc + cur, 0),
                  currency: campaignProjects?.[0]?.product?.prices?.[0]?.currency,
                },
              }),
            )
          : await dispatch(
              createContactSusbscription({
                contactId: contact.id,
                api_key,
                request: {
                  integration: { id: campaign.payment_integration?.id },
                  payment_method: { id: paymentMethod.id },
                  items: campaignProjects.map(cp => ({
                    product: { id: cp.product.id },
                    price: { id: cp?.product?.prices.find(p => p.recurring.interval === selectedPrice.id).id },
                    quantity: parseFloat(cp.amount) * (unit === 'KILO' ? 1 : 1000),
                  })),
                },
              }),
            );

      const r = await stripe.confirmPayment({
        clientSecret: contactSub.client_secret,
        elements: elements,
        confirmParams: { return_url: window.location.href },
        redirect: 'if_required',
      });
      setPaying(false);

      if (r.error) {
        onDone(r.error.payment_intent.status);
        back();
      } else {
        dispatch(
          createTransactions({
            api_key,
            request: campaignProjects.map(cp => ({
              base_currency: {
                amount:
                  parseFloat(cp.amount) *
                  (unit === 'KILO' ? 1 : 1000) *
                  cp?.product?.prices.filter(p => p.recurring.interval === selectedPrice.id).map(p => p.amount),
                currency: 'USD',
              },
              tx_id: nanoid(),
              campaign: { id: campaign.id },
              project: { id: cp.project.id },
              co2_kg_units: parseFloat(cp.amount) * (unit === 'KILO' ? 1 : 1000),
              reference: `${parseFloat(cp.amount) * (unit === 'KILO' ? 1 : 1000)}kg CO2 - ${cp.project?.name}`,
              measurement: measurement ? { id: measurement } : null,
              contact: {
                id: contact.id,
                forename: names[0],
                surname: names[1],
                email: email,
                contact_type: 'PERSON',
              },
            })),
          }),
        );
        onDone(r?.paymentIntent?.status);
        back();
      }
    };

    return (
      <form className="flex-column mt-5 payment-form">
        <div className="flex-column pxy-6">
          <PaymentElement />
          {!paying && (
            <>
              <p className="light-purple-text lato-400-text font-12 pb-1 mt-4">
                Email address for your Digital Certificate and Receipt
              </p>
              <input
                className="flex-1 purchase-input lato-400-text font-16"
                placeholder="Email address"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </>
          )}
        </div>
        {!paying && (
          <>
            <div className="grid price-selector items-center pb-6 px-6">
              {prices.map(p => (
                <div
                  key={p.id}
                  className="flex items-center justify-center price-type cursor"
                  style={{ background: selectedPrice.id === p.id ? '#020819' : '' }}
                  onClick={() => setSelectedPrice(p)}>
                  <label
                    className="lato-700-text font-12"
                    style={{ color: selectedPrice.id === p.id ? '#ffffff' : '' }}>
                    {p.name}
                  </label>
                </div>
              ))}
            </div>
            <div className="flex-column pxy-6 border-top">
              <p className="light-purple-text lato-400-text font-12 pb-1">Full name of the Environmental Beneficiary</p>
              <input
                className="purchase-input lato-400-text font-16"
                placeholder="Full name"
                name={name}
                onChange={e => setName(e.target.value)}
              />
              <div className="flex items-center mt-10">
                <Button
                  className="grey specified-width mr-4"
                  size="large"
                  width="48px"
                  borderRadius="16px"
                  bgColor="#fbfbfb"
                  onClick={back}
                  icon={
                    <img
                      height={16}
                      src="https://api.dev2.meta-carbon.cloud/icons/d10b5484-ed38-11ee-9378-42010a9a0040/active"
                    />
                  }
                />
                <Button
                  className="primary specified-width"
                  size="large"
                  width="316px"
                  borderRadius="16px"
                  label="Pay"
                  onClick={onSubmit}
                  disabled={
                    !stripe || !elements || !name || !email
                    // (minOffset &&
                    //   campaignProjects.map(cp => parseFloat(cp.amount)).reduce((acc, cur) => acc + cur, 0) <
                    //     Math.ceil(minOffset / (unit === 'KILO' ? 1 : 1000)))
                  }
                />
              </div>
            </div>
          </>
        )}
        {paying && (
          <div className="flex items-center justify-center flex-1 loading-gif py-2">
            <img
              height={48}
              src="https://api.dev2.meta-carbon.cloud/icons/d10b5484-ed38-11ee-9378-42010a9a0044/active"
            />
          </div>
        )}
      </form>
    );
  };

  const Payment = () => {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
    return (
      <Elements
        stripe={stripePromise}
        options={{
          mode: 'setup',
          currency: 'usd',
          paymentMethodCreation: 'manual',
          appearance: {
            variables: { borderRadius: '12px', fontFamily: 'Lato' },
            rules: {
              '.Label': {
                color: '#AABAB7',
                fontSize: '12px',
                fontFamily: 'Lato',
              },
            },
          },
        }}>
        <StripePayment />
      </Elements>
    );
  };

  return (
    <PurchaseWrapper className="flex-column flex-1 ">
      <div className="px-6 pt-6 pb-5">
        <label className="quicksand-700-text font-18">Projects</label>
      </div>
      <div className="flex-column flex-1 border-bottom px-6 pb-4 mb-2">
        {campaignProjects?.map(cp => (
          <div key={cp.id} className="flex pb-4">
            <div className="flex project-info pxy-2 relative">
              <img
                srcSet={cp?.project?.image.media?.map(m => `${m.url} ${m.width}w`).join(', ')}
                width={80}
                height={80}
              />
              <div className="flex-column justify-between ml-3 py-1">
                <label className="quicksand-700-text font-16">{cp?.project?.name}</label>
                <div className="flex items-center">
                  <label className="lato-400-text grey-text font-14">
                    ${(cp?.product?.prices?.find(p => p.recurring.interval === 'SINGLE').amount || 0) * 1000} per tonne
                  </label>
                  <div className="flex items-center ml-4">
                    <img
                      height={12}
                      src="https://api.dev2.meta-carbon.cloud/icons/d10b5484-ed38-11ee-9378-42010a9a0041/active"
                    />
                    <label className="lato-400-text grey-text font-14 ml-1">{cp?.project?.location?.country}</label>
                  </div>
                </div>

                <div className="flex items-center">
                  <label className="lato-500-text font-14">Carbon amount:</label>
                  <div className="flex items-center carbon-input ml-2">
                    {/* <CurrencyInput
                      className="mr-1 lato-400-text font-14"
                      decimalsLimit={3}
                      onValueChange={value =>
                        setCampaignProjects(campaignProjects.map(p => (p.id === cp.id ? { ...p, amount: value } : p)))
                      }
                      step={10}
                      value={cp?.amount}
                    /> */}
                    <p className="lato-400-text font-16">
                      {cp?.amount}
                      {unit === 'KILO' ? ' kilo' : ' tonnes'}
                    </p>
                  </div>
                </div>
              </div>
              <img
                className="absolute info-btn cursor"
                height={12}
                src="https://api.dev2.meta-carbon.cloud/icons/d10b5484-ed38-11ee-9378-42010a9a0042/active"
              />
            </div>
            <div
              className="flex items-center justify-center ml-4 cursor"
              onClick={() => setCampaignProjects(campaignProjects.filter(p => p.id !== cp.id))}>
              <img
                height={16}
                src="https://api.dev2.meta-carbon.cloud/icons/d10b5484-ed38-11ee-9378-42010a9a0043/active"
              />
            </div>
          </div>
        ))}
        {/* No needed on payment page.
         {minOffset && (
          <div className="flex items-center mb-2">
            <label className="quicksand-700-text font-16">Recommended amount:</label>
            <label
              className="quicksand-700-text font-16 ml-2"
              style={{
                color:
                  minOffset &&
                  campaignProjects.map(cp => parseFloat(cp.amount)).reduce((acc, cur) => acc + cur, 0) <
                    Math.ceil(minOffset / (unit === 'KILO') ? 1 : 1000)
                    ? 'red'
                    : '',
              }}>
              {Math.ceil(minOffset / (unit === 'KILO' ? 1 : 1000)).toLocaleString('en-US')}{' '}
              {unit === 'KILO' ? 'kilo' : 'tonnes'}
            </label>
          </div>
        )} */}
        <div className="flex items-center">
          <label className="quicksand-700-text font-16">Total:</label>
          <label className="quicksand-700-text font-16 ml-2">
            $
            {campaignProjects
              .filter(sp => sp?.amount)
              .reduce(
                (acc, scope) =>
                  acc +
                  (parseFloat(scope?.amount) || 0) *
                    scope?.product?.prices?.find(p => p.recurring.interval === selectedPrice.id)?.amount *
                    (unit === 'KILO' ? 1 : 1000),
                0,
              )
              .toLocaleString('en-US')}
          </label>
        </div>
      </div>
      <Payment />
      <p className="lato-400-text pb-4 font-12 self-center no-select">
        By purchasing you agree to Meta Carbon <u>Terms of Service</u> and <u>Privacy Policy</u>
      </p>
    </PurchaseWrapper>
  );
};

const PurchaseWrapper = styled.div`
  .project-info {
    border-radius: 12px;
    max-width: 400px;
    width: 400px;
    background: #ffffff;
    overflow-x: hidden;
    border: 1px solid #dadce0;
    img {
      border-radius: 6px;
      object-fit: cover;
    }
  }

  .purchase-input {
    border-radius: 12px;
    border: 1px solid rgba(181, 185, 193, 0.5);
    padding: 12px 16px;
    background: #ffffff;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .purchase-input::placeholder {
    opacity: 0.5;
  }

  .amount-btn {
    border-radius: 8px;
    background: #020819;
    height: 24px;
    min-height: 24px;
    bottom: 16px;
    right: 16px;

    p {
      color: #ffffff;
    }
  }

  .payment-form {
    border: 1px solid rgba(181, 185, 193, 0.5);
    border-radius: 12px;
    background: #ffffff;
    margin: 24px 24px 16px 24px;
  }

  .disabled {
    background: rgba(201, 226, 222, 1);
    border-color: rgba(201, 226, 222, 1);
  }

  .carbon-input {
    width: 120px;
    border-radius: 8px;
    // border: 1px solid rgba(181, 185, 193, 0.5);
    padding: 3px 8px;
    background: #ffffff;

    input {
      border: none;
      background: transparent;
      width: 55px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .info-btn {
    right: 10px;
    bottom: 10px;
  }

  .price-type {
    height: 32px;
    border-radius: 8px;
    background: #f5f5f5;
  }

  .price-selector {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 16px;
  }
  .loading-gif {
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
  }
`;

export default Purchase;
