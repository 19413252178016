import React, { useState } from 'react';
import HttpsRedirect from 'react-https-redirect';
import { useTranslation } from 'react-i18next';
import { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import ReloadIcon from './assets/images/reload2.svg';
import { LanguageProvider } from './context/LanguageContext';
import { ThemePreferenceContextProvider, initialThemeState } from './context/ThemePreferenceContext';
import { getLanguage } from './helpers/i18n';
import './index.css';
import { Router } from './routes/router';
import { init } from './store/features/reloadSlice';
import { CommonStyleWrapper } from './styles/Common.styled';
import { dark, light } from './styles/theme/index';
import './translations/i18n';

const themesMap = {
  light,
  dark,
};

const App = ({ widget, minOffset, measurement }) => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.reload);
  const { i18n } = useTranslation();

  const [language, setLanguage] = useState(getLanguage());
  const [theme, setTheme] = useState(initialThemeState.theme);

  const onLanguageChange = lang => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
  };

  return (
    <LanguageProvider value={{ onLanguageChange, language }}>
      <ThemePreferenceContextProvider value={{ theme, setTheme }}>
        <ThemeProvider theme={themesMap[theme]}>
          <SkeletonTheme baseColor="#F0F0F0" highlightColor="#FAFAFA">
            <CommonStyleWrapper />
            <div className={`theme-${theme} flex-1`}>
              <HttpsRedirect>
                <Router widget={widget} minOffset={minOffset} measurement={measurement} />
              </HttpsRedirect>
            </div>
          </SkeletonTheme>
        </ThemeProvider>
      </ThemePreferenceContextProvider>
    </LanguageProvider>
  );
};

export default App;
