import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { OrganisationContext } from '../../context/organisationContext';
import Projects from '../projects';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { getCampaignProjects, getCampaign, getWidget } from '../../store/features/campaignSlice';
import Purchase from '../purchase';
import Button from '../../components/common/button/button';

const Main = ({ widget_id, minOffset, measurement }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { toasts } = useSelector(state => state.toast);
  const ref = useRef(false);
  const [modal, setModal] = useState({
    type: null,
    content: {},
  });
  const { campaignProjects, campaign, loadingCampaignProjects } = useSelector(state => state.campaign);
  const { id } = useParams();
  const widgetId = widget_id || id;
  const [projectNr, setProjectNr] = useState(0);
  const [selectedProject, setSelectedProject] = useState();
  const [widget, setWidget] = useState();
  const { api_key } = widget || {};
  const { row, column } = widget || {};
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [colums, setColumns] = useState(column);
  const [windowSize, setWindowSize] = useState([window.innerHeight, window.innerWidth]);
  const [buy, setBuy] = useState(false);
  const [done, onDone] = useState();

  useEffect(() => {
    if (api_key && widget) dispatch(getCampaignProjects({ api_key, campaignId: widget.campaign.id }));
  }, [api_key, widget]);

  useEffect(() => {
    if (api_key && widget) dispatch(getCampaign({ api_key, campaignId: widget.campaign.id }));
  }, [api_key, widget]);

  useEffect(() => {
    if (campaignProjects && campaignProjects.length > 0) setSelectedProject(campaignProjects[projectNr]);
  }, [campaignProjects, projectNr]);

  useEffect(() => {
    dispatch(getWidget({ widget_id: widgetId }))
      .then(data => setWidget(data))
      .catch(error => {
        console.error(error);
      });
  }, [widgetId]);

  useEffect(() => {
    const windowSizeHandler = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener('resize', windowSizeHandler);

    return () => {
      window.removeEventListener('resize', windowSizeHandler);
    };
  }, []);
  useEffect(() => {
    const width = ref.current?.offsetWidth;
    setColumns(column * 255 > width ? Math.floor(width / 255) : column);
  }, [column, windowSize]);

  return (
    <MainContentWrapper className="flex-1">
      <OrganisationContext.Provider
        value={{
          modal,
          setModal,
        }}>
        {loadingCampaignProjects ? (
          <div className="flex items-center justify-center flex-1 loading-gif">
            <img
              height={48}
              src="https://api.dev2.meta-carbon.cloud/icons/d10b5484-ed38-11ee-9378-42010a9a0044/active"
            />
          </div>
        ) : (
          <div className="flex-column main items-center justify-between flex-1" ref={ref}>
            <div className="flex main-content flex-1 justify-center">
              {done ? (
                <div className="success-error flex-column items-center">
                  <img
                    className="mt-12 mb-7"
                    height={168}
                    src={`https://api.dev2.meta-carbon.cloud/icons/d10b5484-ed38-11ee-9378-42010a9a004${
                      done === 'succeeded' ? 5 : 6
                    }/active`}
                  />
                  <label className="quicksand-700-text font-24">
                    {done === 'succeeded' ? 'Successful payment!' : 'Payment failed'}
                  </label>
                  {done === 'succeeded' ? (
                    <label className=""></label>
                  ) : (
                    <label className="lato-400-text font-14 mt-2">Something goes wrong, please, try again</label>
                  )}
                  <Button
                    label="Go back"
                    size="large"
                    borderRadius="16px"
                    bgColor="#24CBB1"
                    color="#ffffff"
                    className="primary specified-width mt-10 mb-30"
                    width="316px"
                    onClick={() => {
                      setSelectedProjects([]);
                      onDone(null);
                      // window.location.reload();
                    }}
                  />
                </div>
              ) : buy ? (
                <div>
                  <Purchase
                    api_key={api_key}
                    campaign={campaign}
                    campaignProjects={selectedProjects.filter(sp => sp?.amount)}
                    setCampaignProjects={setSelectedProjects}
                    back={() => setBuy(false)}
                    unit={widget?.unit || 'TONNE'}
                    minOffset={minOffset}
                    onDone={onDone}
                    measurement={measurement}
                  />
                </div>
              ) : (
                <div className="flex flex-1 fit-content justify-center">
                  {campaignProjects && (
                    <Projects
                      campaignProjects={campaignProjects}
                      selectedProjects={selectedProjects}
                      setSelectedProjects={setSelectedProjects}
                      row={row}
                      column={colums}
                      setBuy={setBuy}
                      unit={widget?.unit || 'TONNE'}
                      minOffset={minOffset}
                    />
                  )}
                </div>
              )}
            </div>
            <div className="flex items-end mt-4 pb-4 powered-by-icon no-select">
              <p className="regular-text font-10 mr-2 mb-05">Powered by</p>
              <img
                height={32}
                src="https://api.dev2.meta-carbon.cloud/icons/d10b5484-ed38-11ee-9378-42010a9a0035/active"
              />
            </div>
          </div>
        )}
      </OrganisationContext.Provider>
    </MainContentWrapper>
  );
};

const MainContentWrapper = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  overflow-y: scroll;

  .main-content {
    background: #fbfbfb;
    border: 1px solid rgba(181, 185, 193, 0.1);
    border-radius: 16px;
    min-width: 385px;
  }

  .main {
    width: 100%;
    display: flex;
  }

  .buy-btn {
    max-width: 428px;
  }

  .success-error {
    width: 504px;
  }
`;

export default Main;

// http://localhost:3001/27baba7e-e8fb-11ee-9378-42010a9a0012?payment_intent=pi_3PE9xFC1HK51BkXN0DPQlcVd&payment_intent_client_secret=pi_3PE9xFC1HK51BkXN0DPQlcVd_secret_D2TsqQf5fZ2HRZi5OH3Upurf1&redirect_status=succeeded
